import React, { useEffect } from 'react';
import anime from 'animejs';
import Navbar from "./includes/Navbar";
import { useLocation, Link } from 'react-router-dom';
import line from "../assets/howwedeliver/line.webp"
import keyImg from "../assets/howwedeliver/keyimg.webp";
import one from "../assets/howwedeliver/one.png";
import two from "../assets/howwedeliver/two.png";
import three from "../assets/howwedeliver/three.png";
import thrive from "../assets/howwedeliver/thrive.png";
import thriveSmall from "../assets/howwedeliver/thrive-small.png";
import AOS from 'aos';
AOS.init();

export default function Howwedeliver() {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const staggerVisualizerEl = document.querySelector('.stagger-visualizer');
        const fragment = document.createDocumentFragment();
        const grid = [17, 17];
        const col = grid[0];
        const row = grid[1];
        const numberOfElements = col * row;

        for (let i = 0; i < numberOfElements; i++) {
            fragment.appendChild(document.createElement('div'));
        }

        staggerVisualizerEl.appendChild(fragment);

        const staggersAnimation = anime.timeline({
            targets: '.stagger-visualizer div',
            easing: 'easeInOutSine',
            delay: anime.stagger(50),
            loop: true,
            autoplay: false
        })
            .add({
                translateX: [
                    { value: anime.stagger('-.1rem', { grid: grid, from: 'center', axis: 'x' }) },
                    { value: anime.stagger('.1rem', { grid: grid, from: 'center', axis: 'x' }) }
                ],
                translateY: [
                    { value: anime.stagger('-.1rem', { grid: grid, from: 'center', axis: 'y' }) },
                    { value: anime.stagger('.1rem', { grid: grid, from: 'center', axis: 'y' }) }
                ],
                duration: 1000,
                scale: .5,
                delay: anime.stagger(100, { grid: grid, from: 'center' })
            })
            .add({
                translateX: () => anime.random(-10, 10),
                translateY: () => anime.random(-10, 10),
                delay: anime.stagger(8, { from: 'last' })
            })
            .add({
                translateX: anime.stagger('.25rem', { grid: grid, from: 'center', axis: 'x' }),
                translateY: anime.stagger('.25rem', { grid: grid, from: 'center', axis: 'y' }),
                rotate: 0,
                scaleX: 2.5,
                scaleY: .25,
                delay: anime.stagger(4, { from: 'center' })
            })
            .add({
                rotate: anime.stagger([90, 0], { grid: grid, from: 'center' }),
                delay: anime.stagger(50, { grid: grid, from: 'center' })
            })
            .add({
                translateX: 0,
                translateY: 0,
                scale: .5,
                scaleX: 1,
                rotate: 180,
                duration: 1000,
                delay: anime.stagger(100, { grid: grid, from: 'center' })
            })
            .add({
                scaleY: 1,
                scale: 1,
                delay: anime.stagger(20, { grid: grid, from: 'center' })
            })

        staggersAnimation.play();
    });

    return (
        <div>
            <div className='yellow-bg'>
                <section className="pt-2 pb-1">
                    <Navbar />
                </section>
                <div className='max-width-container'>
                    <div className='deliver-bg row max-sec mt-3'>
                        <div className='col-md-6 col-sm-12 my-auto'>
                            <h1>How We Deliver</h1>
                            <p className='mt-3'>What if each piece of your application can be a small,
                                independent & interactive element
                                like a lego block?
                            </p>
                        </div>
                        <div className='col-sm-12 col-md-6 stagger-p d-flex justify-content-center'>
                            <div className="stagger-visualizer"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='build-brick'>
                <div className='max-width-container'>
                    <h1>We all build brick-by-brick, but how ‘micro’ do you go?</h1>
                    <p className='text-center pb-2'>Our Microservices approach lets you to build to scale</p>
                    <div className='row justify-content-center mt-5'>
                        <div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
                            <img src={one} alt='one' className='img-fluid ' />
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
                            <img src={two} alt='one' className='img-fluid ' />
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
                            <img src={three} alt='one' className='img-fluid ' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='principles max-width-container '>
                <div className='row max-sec'>
                    <div className='col-md-6 col-sm-12 col-lg-6 my-auto'>
                        <h1 className='pt-2'>Our Key Principles</h1>
                        <p className='mb-5'>Build high quality and well engineered products</p>
                        <div className='d-flex'>
                            <div>
                                <img src={line} alt='line' className='img-fluid me-3' />
                            </div>
                            <div>
                                <ul>
                                    <li>Own the entire concept-to-operations process</li>
                                    <li>Deliver with a agile and robust DevOps capability</li>
                                    <li>Accelerate your time-to-market</li>
                                    <li>Samll teams with BIG results</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 col-lg-6 text-center'>
                        <img src={keyImg} alt='key' className='img-fluid' />
                    </div>
                </div>
            </div>
            <div className='microservices'>
                <div className='d-none d-sm-none d-md-block'>
                    <div className="max-width-container">
                        <img src={thrive} className='img-fluid' alt='thrive' />
                    </div>
                </div>
                <div className='d-sm-block d-md-none'>
                    <img src={thriveSmall} className='img-fluid' alt='thrive' />
                </div>
            </div>
            {/* Letus */}
            <div className="letus">
                <div className="max-width-container max-sec">
                    <div className="letus-sub">
                        <p className="letus-content">Let us build your tomorrow, today</p>
                        <div>
                            <Link to="/proposal">
                                <button className="border-0 letus-btn"><span>Get In Touch</span></button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}
