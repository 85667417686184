import Navbar from "./includes/Navbar";
import React, { useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export default function Proposal() {

    //use form validation
    const form = useRef();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        console.log(data);
        emailjs.sendForm('service_yx787ui', 'template_al6lbrs', form.current, 'WmZrSZDWbVJ2wSHu2')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        setOpen(true);
        reset();
    };

    const onChange = (event) => {
        console.log('change', event.target.value);
    };

    // snackbar
    const [open, setOpen] = useState(false);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <div className='proposal'>
                <section className="pt-2 pb-1">
                    <Navbar />
                </section>
                <div className='p-hero max-width-container'>
                    <h1 className="mb-4">Share your thoughts</h1>
                    <h4 className="mb-4">Happy to see you here</h4>
                    <section className='project-enquiry'>
                        <h5>Project Enquiry</h5>
                        <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                            <div className='row'>
                                <div className='col-md-8'>
                                    <div className='row mb-3'>
                                        <div className='col-lg-6'>
                                            <TextField id="name" size="small" label="Name*" fullWidth className='mb-3' variant="outlined" name='name'                                             {...register("name", { required: "Name is required." })}
                                                error={Boolean(errors.name)}
                                                helperText={errors.name?.message} />

                                            <TextField type='email' size="small" id="email" label="Email*" fullWidth className='mb-3' variant="outlined" name='email'
                                                {...register("email", { required: "Email is required." })}
                                                error={Boolean(errors.email)}
                                                helperText={errors.email?.message} />
                                        </div>
                                        <div className='col-lg-6'>
                                            <TextField id="company" size="small" label="Company / Organization*" fullWidth className='mb-3' variant="outlined" name='company'
                                                {...register("company", { required: "Company Name is required." })}
                                                error={Boolean(errors.company)}
                                                helperText={errors.company?.message} />

                                            <TextField type="number" size="small" id="phone" label="Phone*" fullWidth className='mb-3' variant="outlined" name='phone'
                                                {...register("phone", { required: "Phone Number is required." })}
                                                error={Boolean(errors.phone)}
                                                helperText={errors.phone?.message} />
                                        </div>
                                    </div>
                                    <h6 className='mt-4'>What modules do you need for your project?</h6>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <div className="form-check mt-3">
                                                <input className="form-check-input" type="checkbox" value="App development" id="app" name='App development' onChange={onChange} {...register("checkbox", { required: "Module is required." })} />
                                                <label className="form-check-label" for="app">
                                                    App development
                                                </label>
                                            </div>
                                            <div className="form-check mt-3">
                                                <input className="form-check-input" type="checkbox" value="UI Design" id="uidesign" onChange={onChange} {...register("checkbox", { required: "Module is required." })} name='checkbox' />
                                                <label className="form-check-label" for="uidesign">
                                                    UI Design
                                                </label>
                                            </div>
                                            <div className="form-check mt-3">
                                                <input className="form-check-input" type="checkbox" value="Mobile App" id="mobileapp" onChange={onChange} {...register("checkbox", { required: "Module is required." })} name='checkbox' />
                                                <label className="form-check-label" for="mobileapp">
                                                    Mobile App
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="form-check mt-3">
                                                <input className="form-check-input" type="checkbox" value="UX Design" id="uxdesign" onChange={onChange} {...register("checkbox", { required: "Module is required." })} name='checkbox' />
                                                <label className="form-check-label" for="uxdesign">
                                                    UX Design
                                                </label>
                                            </div>
                                            <div className="form-check mt-3">
                                                <input className="form-check-input" type="checkbox" value="Website" id="website" onChange={onChange} {...register("checkbox", { required: "Module is required." })} name='checkbox' />
                                                <label className="form-check-label" for="website">
                                                    Website
                                                </label>
                                            </div>
                                            <div className="form-check mt-3">
                                                <input className="form-check-input" type="checkbox" value="Other" id="other" onChange={onChange} {...register("checkbox", { required: "Module is required." })} name='checkbox' />
                                                <label className="form-check-label" for="other">
                                                    Other
                                                </label>
                                            </div>
                                        </div>
                                        {errors.checkbox && <small className="text-danger mt-2">{errors.checkbox.message}</small>}
                                    </div>
                                    <div className="form-floating mt-4">
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="At least one line that describes your idea and the timline for your project*"
                                            multiline
                                            fullWidth
                                            rows={2}
                                            name='description'
                                            {...register("description", { required: "description is required." })}
                                            error={Boolean(errors.description)}
                                            helperText={errors.description?.message} />
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-lg-6 mt-2'>
                                            <h6>Please mention your budget and timeline for the project</h6>
                                        </div>
                                        <div className='col-lg-6'>
                                            <TextField id="class" size="small" label="Budget*" fullWidth className='mb-3' variant="outlined" name='budget'
                                                {...register("budget", { required: "Budget is required." })}
                                                error={Boolean(errors.budget)}
                                                helperText={errors.budget?.message} />
                                        </div>
                                    </div>
                                    {/* <div className='row mt-4'>
                                        <h6>If you have a requirement brief or document, share it with us here:</h6>
                                        <div className='col-lg-6'>
                                            <div>
                                                <input {...register("file", { required: true })} class="form-control"
                                                    id="formFileLg"
                                                    type="file"
                                                    name="file" />
                                                {errors.file && <p className='ms-3 file-upload-err'>File Upload is required</p>}
                                            </div>
                                        </div>
                                        <div className='col-lg-6 mt-1'>
                                            <small>File size not more than 5 MB</small>
                                        </div>
                                    </div> */}
                                    <button className="btn btn-submit rounded-pill px-5 my-4 mb-5" type="submit">Submit</button>
                                </div>
                            </div>
                        </form>
                    </section>
                </div>
            </div>
            {/* snackbar */}
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }} >
                        Proposal sent successfully!
                    </Alert>
                </Snackbar>
            </Stack>
            {/* snackbar */}
        </>
    )
}
