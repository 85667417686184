import React from 'react';
import Navbar from "./includes/Navbar";
import uc from "../assets/uc.webp";

export default function ClientSuccess() {
  return (
    <div>
      <section className="pt-2 pb-1">
        <Navbar />
      </section>
      <div className="services-main text-center max-width-container mb-5">
        <h1 className='pb-5'>Client Success</h1>
        <img src={uc} className='img-fluid w-75' />
      </div>
    </div>
  )
}
