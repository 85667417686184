import React from 'react';
import Footer from './includes/Footer';
import { Outlet } from 'react-router-dom';

const Index = () => {
    return (
        <>
            <Outlet />
            <Footer />
        </>
    );
};

export default Index;
