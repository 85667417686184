
export const testimonials = {
    dots: true,
    infinite: true,
    pauseOnHover: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    className: "center",
    centerMode: true,
    centerPadding: "0px",
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplaySpeed: 6000,
            }
        }
    ]
};

export const fade_imgs = {
    dots: false,
    fade: true,
    autoplay: true,
    infinite: true,
    pauseOnHover: false,
    arrows: false,
    speed: 500,
    autoplaySpeed: 7500,
    slidesToShow: 1,
    slidesToScroll: 1
};

export const fade_texts = {
    dots: false,
    fade: true,
    autoplay: true,
    infinite: true,
    pauseOnHover: false,
    arrows: false,
    speed: 500,
    autoplaySpeed: 7500,
    slidesToShow: 1,
    slidesToScroll: 1
};

export const products = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    speed: 500,
    autoplaySpeed: 7000,
    arrows: false,
    pauseOnHover: false
};

export const services = {
    dots: false,
    infinite: true,
    pauseOnHover: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        }
    ]
};

export const teams = {
    dots: true,
    infinite: true,
    pauseOnHover: false,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
};

export const employees = {
    dots: true,
    infinite: true,
    pauseOnHover: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
};