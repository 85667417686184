import React from 'react';
import Navbar from "./includes/Navbar";
import bv from "../assets/products/bv.png";
import ggv from "../assets/products/ggv.png";
import hydrameet from "../assets/products/hydrameet.png";
import smefe from "../assets/products/smefe.png";
import collaborate from "../assets/products/collaborate.png";
import manage from "../assets/products/manage.png";
import insigts from "../assets/products/insigts.png";
import communicate from "../assets/products/communicate.png";
import secure from "../assets/products/secure.png";
import giga from "../assets/products/giga.png";
import boardv from "../assets/products/boardv.png";
import chat from "../assets/products/chat.png";
import shake from "../assets/products/shake.png";
import lock from "../assets/products/lock.png";
import ui from "../assets/products/ui.png";
import hexas from "../assets/products/hexas.png";
import Slider from "react-slick";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import Fab from "@material-ui/core/Fab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

export const data = [
  {
    cover: bv,
    title: "bv",
  },
  {
    cover: ggv,
    title: "ggv",
  },
  {
    cover: hydrameet,
    title: "hydrameet",
  },
  {
    cover: smefe,
    title: "smefe",
  },
];

const settings = {
  dots: true,
  infinite: true,
  pauseOnHover: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  className: "center",
  centerMode: true,
  centerPadding: "15%",
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 6000,
      }
    }
  ]
};

export const Card = React.memo(function (props) {
  const { data, dataIndex } = props;
  const { cover } = data[dataIndex];
  return (
    <div
      style={{
        width: "100%",
        height: 500,
        userSelect: "none",
      }}
      className="my-slide-component"
    >
      <img
        style={{
          // height: "100%",
          // width: "100%",
          objectFit: "cover",
          borderRadius: 0,
        }}
        draggable={false}
        src={cover}
      />
    </div>
  );
});

export default function Products(props) {
  const ref = React.useRef();
  return (
    <div>
      <section className="pt-2 pb-1">
        <Navbar />
      </section>
      <div className="products text-center">
        <div className='max-width-container'>
          <h1>Our Products</h1>
          <p className='mb-5'>We have partnered with our clients to enhance user experience on various digital platforms <br /> and superpowered businesses across industries through our data-driven approach and <br /> processes that result in design solutions that perform.</p>
          <div style={{ width: "100%", position: "relative" }}>
            <ResponsiveContainer
              carouselRef={ref}
              render={(parentWidth, carouselRef) => {
                let currentVisibleSlide = 5;
                if (parentWidth <= 1440) currentVisibleSlide = 5;
                if (parentWidth <= 1080) currentVisibleSlide = 3;
                return (
                  <StackedCarousel
                    ref={carouselRef}
                    slideComponent={Card}
                    slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
                    carouselWidth={parentWidth}
                    data={data}
                    currentVisibleSlide={currentVisibleSlide}
                    maxVisibleSlide={5}
                    useGrabCursor
                    autoplay={true}
                  />
                );
              }}
            />
            <>
              <Fab
                style={{ position: "absolute", top: "40%", left: 10, zIndex: 10 }}
                size="small"
                onClick={() => {
                  ref.current?.goBack();
                }}
              >
                <ArrowBackIcon />
              </Fab>
              <Fab
                style={{ position: "absolute", top: "40%", right: 10, zIndex: 10 }}
                size="small"
                onClick={() => {
                  ref.current?.goNext(6);
                }}
              >
                <ArrowForwardIcon />
              </Fab>
            </>
          </div>
        </div>
      </div>
      <div className='highlights'>
        <div className='max-width-container'>
          <h1>Product Highlights</h1>
          <div className='row justify-content-center text-center'>
            <div className='col-sm-4'>
              <img src={collaborate} className='img-fluid' width='70%'></img>
            </div>
            <div className='col-sm-4'>
              <img src={manage} className='img-fluid' width='70%'></img>
            </div>
            <div className='col-sm-4'>
              <img src={insigts} className='img-fluid' width='70%'></img>
            </div>
            <div className='col-sm-4'>
              <img src={communicate} className='img-fluid' width='70%'></img>
            </div>
            <div className='col-sm-4'>
              <img src={secure} className='img-fluid' width='70%'></img>
            </div>
          </div>
        </div>
      </div>
      <div className='product-grid max-sec'>
        <div className='row max-width-container mb-5'>
          <div className='col-md-6 col-lg-6 col-sm-12 mb-4'>
            <img src={boardv} className='img-fluid'></img>
          </div>
          <div className='col-md-6 col-lg-6 col-sm-12 my-auto'>
            <div className='d-flex justify-content-between'>
              <h1>Board.Vision</h1>
              <div>
                <img src={chat} className='me-3'></img>
                <img src={shake} className='me-3'></img>
                <img src={lock} ></img>
              </div>
            </div>

            <p>Board.Vision is the next generation board portal designed with industry-leading features to accelerate the board’s decision-making process.</p>
            <ul>
              <li>Digitalizes the Boardroom with:</li>
              <li>1. Automated resolutions.</li>
              <li>2. Intelligent corporate database with digital statutory registers.</li>
              <li>3. On demand approvals.</li>
              <li>4. In-app chat to facilitate board discussion.</li>
              <li>5. Auditable meeting documents and resources.</li>
              <li>6. Smart scheduling of Board meetings.</li>
            </ul>
            <div className='d-flex'>
              <img src={ui} className='me-2'></img>
              <img src={ui} className='me-2'></img>
              <img src={ui}></img>
            </div>
          </div>
        </div>
        <div className='row max-width-container'>
          <div className='col-md-6 col-lg-6 col-sm-12 my-auto mb-4'>
            <div className='d-flex justify-content-between'>
              <h1>Gigavus</h1>
              <div>
                <img src={chat} className='me-3'></img>
                <img src={shake} className='me-3'></img>
                <img src={lock} ></img>
              </div>
            </div>

            <p>Board.Vision is the next generation board portal designed with industry-leading features to accelerate the board’s decision-making process.</p>
            <ul>
              <li>Digitalizes the Boardroom with:</li>
              <li>1. Automated resolutions.</li>
              <li>2. Intelligent corporate database with digital statutory registers.</li>
              <li>3. On demand approvals.</li>
              <li>4. In-app chat to facilitate board discussion.</li>
              <li>5. Auditable meeting documents and resources.</li>
              <li>6. Smart scheduling of Board meetings.</li>
            </ul>
            <div className='d-flex'>
              <img src={ui} className='me-2'></img>
              <img src={ui} className='me-2'></img>
              <img src={ui}></img>
            </div>
          </div>
          <div className='col-md-6 col-lg-6 col-sm-12'>
            <img src={giga} className='img-fluid'></img>
          </div>
        </div>
      </div>
      <div className="products text-center">
        <div className='max-width-container'>
          <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h1>
          <p>With more than 20 years in the UI/UX sphere, we craft experiences that match user <br />expectations, thus enabling brands to achieve their business vision.</p>
          <img src={hexas} className='img-fluid' />
        </div>
      </div>
    </div>
  )
}