import React from 'react'
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <section className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="banner-head-txt text-center">
        <span>404<span className="d-block"></span>Page Not Found</span>
      </div>
      <div className='text-center'>
        <Link to="/">
          <button className="btn btn-submit rounded-pill px-5 my-4 mb-5">Go Back To Home</button>
        </Link>
      </div>
    </section>

  )
}

export default PageNotFound;