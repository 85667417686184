import React, { useEffect, useRef, useState } from "react";
import Navbar from "./includes/Navbar";
import { useParams, useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export default function JobDetails({ JobListData }) {
  console.log("JobListData", JobListData);

  // snackbar
  const [open, setOpen] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //use form validation
  const form = useRef();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    emailjs
      .sendForm(
        "service_yx787ui",
        "template_azusk6u",
        form.current,
        "WmZrSZDWbVJ2wSHu2"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setOpen(true);
    reset();
  };

  const { slug } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <section className="pt-2 pb-1">
        <Navbar />
      </section>
      <section className="max-width-container">
        <div className="border job-details max-width-container py-4 px-3 mb-4">
          {JobListData.filter((data) => data.slug === slug).map(
            (data, index) => (
              <div key={index}>
                <h1>{data.title}</h1>
                <h6 className="fw-bold">
                  <i
                    className="fa fa-map-marker fs-5 me-2"
                    aria-hidden="true"
                  ></i>{" "}
                  {data.location}
                </h6>
                <p className="mt-3">Experience: {data.experience}</p>
                <p>Employment Type: {data.workType}</p>
                <hr className="my-4" />
                <div className="mb-4">
                  <h4>About the business and the role:</h4>
                  <p dangerouslySetInnerHTML={{ __html: data.jobAbout }}></p>
                </div>
                <div className="mb-4">
                  <h4>Responsibilities:</h4>
                  <div
                    dangerouslySetInnerHTML={{ __html: data.responsibility }}
                  ></div>
                </div>
                {data.skills &&
                  <div className="mb-4">
                    <h4>Skills and experience:</h4>
                    <div dangerouslySetInnerHTML={{ __html: data.skills }}></div>
                  </div>
                }
                {data.goodtohave ? (
                  <div className="mb-4">
                    <h4>Good to have:</h4>
                    <div
                      dangerouslySetInnerHTML={{ __html: data.goodtohave }}
                    ></div>
                  </div>
                ) : (
                  ""
                )}
                {data.softskills ? (
                  <div className="mb-4">
                    <h4>Soft Skills:</h4>
                    <div
                      dangerouslySetInnerHTML={{ __html: data.softskills }}
                    ></div>
                  </div>
                ) : (
                  ""
                )}
                {/* form */}
                <hr className="my-4" />
                <h5>Submit Your Response</h5>
                <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-6">
                      <TextField
                        id="outlined-basic"
                        name="name"
                        label="Name:"
                        variant="outlined"
                        className="mb-3"
                        size="small"
                        fullWidth
                        {...register("name", { required: "Name is required." })}
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                      />
                      <TextField
                        id="outlined-basic"
                        name="email"
                        label="Email:"
                        variant="outlined"
                        className="mb-3"
                        size="small"
                        fullWidth
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please enter a valid email",
                          },
                        })}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                      />
                      <TextField
                        id="outlined-basic"
                        // label="Position applied for:"
                        variant="outlined"
                        fullWidth
                        className="mb-3"
                        size="small"
                        type="text"
                        name="position"
                        value={data.title}
                        {...register("position", {
                          required: "Position applied for is required.",
                        })}
                        error={Boolean(errors.position)}
                        helperText={errors.position?.message}
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextField
                        id="outlined-basic"
                        label="Current Employer:"
                        variant="outlined"
                        fullWidth
                        className="mb-3"
                        size="small"
                        name="currentEmployer"
                        {...register("currentEmployer", {
                          required: "Current Employer is required.",
                        })}
                        error={Boolean(errors.currentEmployer)}
                        helperText={errors.currentEmployer?.message}
                      />
                      <TextField
                        id="outlined-basic"
                        label="Phone:"
                        variant="outlined"
                        fullWidth
                        className="mb-3"
                        size="small"
                        type="number"
                        name="phone"
                        {...register("phone", {
                          required: "Phone Number is required.",
                        })}
                        error={Boolean(errors.phone)}
                        helperText={errors.phone?.message}
                      />
                    </div>
                  </div>
                  {/* File Attachment UI */}
                  {/* <div className='row'>
              <div className='col-lg-6'>
                <div>
                  <input  {...register("picture", { required: true })}
                    class="form-control"
                    type="file"
                    name="picture"
                  />
                  {errors.picture && <p className='ms-3 file-upload-err'>File Upload is required</p>}
                </div>
              </div>
              <div className='col-lg-6 mt-1'>
                <small>CV size not more than 5 MB</small>
              </div>
            </div> */}

                  <h5 className="mt-4">Social Network and Web Links</h5>
                  <div className="row">
                    <div className="col-sm-12 col-lg-6">
                      <TextField
                        id="facebook"
                        label="Facebook"
                        fullWidth
                        className="mb-3"
                        variant="outlined"
                        size="small"
                        name="facebook"
                      />
                      <TextField
                        id="github"
                        label="Github"
                        fullWidth
                        className="mb-3"
                        variant="outlined"
                        size="small"
                        name="github"
                      />
                      <TextField
                        id="linkedin"
                        label="Linkedin"
                        fullWidth
                        className="mb-3"
                        variant="outlined"
                        size="small"
                        name="linkedin"
                      />
                    </div>
                  </div>
                  <h6 className="mt-1 mb-3">
                    Please drop your resume at{" "}
                    <a href="mailto:careers@instrive.in">careers@instrive.in</a>
                  </h6>
                  <button
                    className="btn btn-submit rounded-pill px-5 mt-2"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
            )
          )}
        </div>
      </section>
      {/* snackbar */}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Your form was submitted successfully!
          </Alert>
        </Snackbar>
      </Stack>
      {/* snackbar */}
    </>
  );
}
