import React from 'react';
import { useState, useEffect } from "react";
import logo from '../../assets/logo.svg';
import { useLocation, NavLink } from 'react-router-dom'

export default function Navbar() {

    // Sticky Menu Area

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e) => {
        const header = document.querySelector('.header-section');
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    const [isNavExpanded, setIsNavExpanded] = useState(false)
    const location = useLocation();
    const currentUrl = location.pathname;

    const MENU_LIST = [
        {
            id: 1,
            title: 'Who We Are',
            path: "who-we-are",
        },
        {
            id: 2,
            title: 'How We Deliver',
            path: "how-we-deliver",
        },
        {
            id: 3,
            title: 'Services',
            path: "services",
        },
        {
            id: 4,
            title: 'Careers',
            path: "careers",
        },
        {
            id: 5,
            title: "Get A Proposal",
            path: "proposal",
        },
        // {
        //     id: 6,
        //     title: "Products",
        //     path: "products",
        // },
        // {
        //     id: 7,
        //     title: "Client Success",
        //     path: "clientsuccess",
        // },
    ];

    useEffect(() => {
        changeTitle();
    }, []);

    const changeTitle = () => {
        let title = "Instrive Softlabs Pvt Ltd: Digital DevOps";
        if (currentUrl) {
            let getPathTitle = getPageTitle(currentUrl.substring(1));
            if (getPathTitle) {
                document.title = `${getPathTitle.title} | ${title}`;
            } else {
                document.title = `${title}`;
            }
        }
    };

    const getPageTitle = (currentUrl) => {
        let result = MENU_LIST.find((resp) => resp.path == currentUrl);
        return result;
    };

    return (
        <header className="header-section">
            <nav className="navigation">
                <NavLink to="/" className="brand-name">
                    <img src={logo} alt='logo' />
                </NavLink>
                <button
                    className="ham-btn" aria-label="hamburger"
                    onClick={() => {
                        setIsNavExpanded(!isNavExpanded)
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#565254"
                    >
                        <path
                            fillRule="evenodd"
                            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
                <div
                    className={
                        isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
                    }
                >
                    <ul>
                        {MENU_LIST.map((data, index) => (
                            <li key={index}>
                                {data.id !== 5 ?
                                    <NavLink activeclassname='active' to={'/' + data.path}>{data.title}</NavLink>
                                    :
                                    <div className="d-flex justify-content-center">
                                        <NavLink to={'/' + data.path} >
                                            <button className={
                                                currentUrl === '/proposal' ? " btn-yellow-bg rounded-pill py-3 border-0" : "btn-gray-bg rounded-pill py-3 border-0"
                                            }>{data.title}</button>
                                        </NavLink>
                                    </div>
                                }
                            </li>
                        ))}
                    </ul>
                </div>
            </nav >
        </header>
    )
}
