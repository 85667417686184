import React, { useRef } from 'react';
import Typewriter from 'typewriter-effect';
import AOS from 'aos';
import Slider from "react-slick";
import Marquee from "react-fast-marquee";
import Particles from 'react-tsparticles';
import { loadFull } from "tsparticles";
import { Link } from "react-router-dom";
import Navbar from "./includes/Navbar";
import arrowRight from "../assets/arrow-right.svg";
import tech from "../assets/tech.webp";
import tech2 from "../assets/tech-2.webp";
import service from "../assets/services.webp";
import quote from "../assets/quote.svg";
import prev from "../assets/previous.webp";
import nex2 from "../assets/next.webp";
import hydrameet from "../assets/client-logos/hydrameet.webp";
import nic from "../assets/client-logos/nic.webp";
import channelRunway from "../assets/client-logos/channel-runway.webp";
import halbe from "../assets/client-logos/halbe.webp";
import boardV from "../assets/client-logos/board-vision.webp";
import synplus from "../assets/client-logos/synplus.webp";
import sixD from "../assets/client-logos/six-degree.webp";
import trustedS from "../assets/client-logos/trusted-source.webp";
import proxtera from "../assets/client-logos/proxtera.webp";
import sme from "../assets/client-logos/sme.webp";
import trusted from "../assets/client-logos/trusted.webp";

import { content, servicesImgs, testi_imgs, personImgs, personNamesAndPosts } from "./helpers/Data";
import { testimonials, fade_imgs, fade_texts, products, services } from "./helpers/Slider";

const particlesInit = async (main) => {
  await loadFull(main);
};

const particlesLoaded = (container) => {
  // console.log(container);
};

AOS.init({
  duration: 1200,
  delay: 500,
  once: true
});

export default function Home() {

  const customeSlider = useRef();

  const previous = () => {
    customeSlider.current.slickNext();
  };

  const next = () => {
    customeSlider.current.slickPrev();
  };

  return (
    <>
      {/* Particles Section */}
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: "#F2F2F2",
            },
          },
          fullScreen: {
            enable: false,
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.5,
              },
            },
          },
          particles: {
            color: {
              value: ["#939393", "#FFCB20"]
            },
            links: {
              color: "#000000",
              distance: 100,
              enable: true,
              opacity: 0.4,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 1.2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 900,
              },
              value: 25,
            },
            opacity: {
              value: 0.6,
            },
            shape: {
              type: "polygon",
              polygon: {
                "nb_sides": 6
              },
            },
            size: {
              value: { min: 4, max: 7 },
            },
          },
          detectRetina: true,
        }} />
      {/* Navbar Section */}
      <div className='instrive'>
        <div className='yellow-bg'>
          <section className="pt-2 pb-1">
            <Navbar />
          </section>
          {/* Banner Section */}
          <section className="home-banner max-width-container pt-4">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 cus-t-margin">
                <div className="d-flex align-items-center">
                  <div className="small-line"></div>
                  <div className="we-are-txt">WE ARE</div>
                </div>
                <div className="banner-head-txt">
                  <span>Building Tomorrow,<span className="d-block"></span>Today</span>
                </div>
                <div className="banner-sub-head">
                  <span>
                    <Typewriter
                      options={{
                        strings: ['Cloud native, Micro-services driven applications built to scale', 'With trusted partners who take us beyond just development', 'Conceiving, Designing, Building, and Managing apps that delight', 'Turning your ideas into products'],
                        autoStart: true,
                        loop: true,
                        delay: 60,
                        deleteSpeed: 2
                      }}
                    />
                  </span>
                </div>
                <Link to="/proposal">
                  <button className="rounded-pill btn-gray-arrow-bg py-2 border-0"><span className="btn-txt-left-space">Get In
                    Touch</span><img src={arrowRight} className="rounded-circle arrow-p-space" alt='arrow' /></button>
                </Link>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 img-fade">
                <Slider {...fade_imgs} className='slider-bg'>
                  {personImgs.map((img, index) => (
                    <div key={index}>
                      <img src={img} className='img-fluid' alt='persons' />
                    </div>
                  ))}
                </Slider>
                <div className='person-name-tot-div'>
                  <Slider {...fade_texts}>
                    {personNamesAndPosts.map((data, index) => (
                      <div className='person-name-txt' key={index}>
                        <span>{data.name}</span>
                        <p>{data.post}</p>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* Logo Slider Section */}
      <section className="max-width-container max-sec pt-5">
        <Marquee speed={45}>
          <div>
            <img
              src={hydrameet}
              alt="hydrameet"
              loading="lazy" />
          </div>
          <div>
            <img
              src={nic}
              alt="nic"
              loading="lazy" />
          </div>
          <div>
            <img
              src={channelRunway}
              alt="channelRunway"
              loading="lazy" />
          </div>
          <div>
            <img
              src={halbe}
              alt="halbe"
              loading="lazy" />
          </div>
          <div>
            <img
              src={boardV}
              alt="boardV"
              loading="lazy" />
          </div>
          <div>
            <img
              src={synplus}
              alt="synplus"
              loading="lazy" />
          </div>
          <div>
            <img
              src={sixD}
              alt="sixD"
              loading="lazy" />
          </div>
          <div>
            <img
              src={trustedS}
              alt="trustedS"
              loading="lazy" />
          </div>
          <div>
            <img
              src={proxtera}
              alt="proxtera"
              loading="lazy" />
          </div>
          <div>
            <img
              src={sme}
              alt="sme"
              loading="lazy" />
          </div>
          <div>
            <img
              src={trusted}
              alt="trusted"
              loading="lazy" />
          </div>
        </Marquee>
      </section>
      {/* Panel Section */}
      <div className="panel pt-5">
        <div className="max-width-container">
          <div className="row max-sec">
            <div className="col-lg-4">
              <h3 className="py-2" data-aos="fade-up">Three years old with a track record that speaks for itself</h3>
            </div>
            <div className="col-lg-8 d-none d-sm-none d-md-block">
              <div className="row">
                <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
                  <span className="number-text">8</span>
                  <div className="under-line"></div>
                  <p className="card-text">Enterprise Grade SaaS products built in since 2019</p>
                </div>
                <div className="col-lg-4" data-aos="fade-up" data-aos-delay="400">
                  <span className="number-text">22</span>
                  <div className="under-line"></div>
                  <p className="card-text">Clients served across <br /> the world</p>
                </div>
                <div className="col-lg-4" data-aos="fade-up" data-aos-delay="600">
                  <span className="number-text">3</span>
                  <div className="under-line"></div>
                  <p className="card-text">Start-ups fund raised with products we built for them</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8 d-sm-block d-md-none">
              <div className="row">
                <div className="col-lg-4 mb-3" data-aos="fade-up" data-aos-delay="200">
                  <div className="d-flex">
                    <div className="me-5">
                      <span className="number-text">8</span>
                      <div className="under-line"></div>
                    </div>
                    <div>
                      <p className="card-text mt-2">Enterprise Grade SaaS products built in since 2019</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-3" data-aos="fade-up" data-aos-delay="400">
                  <div className="d-flex">
                    <div className="me-3">
                      <span className="number-text">22</span>
                      <div className="under-line"></div>
                    </div>
                    <div>
                      <p className="card-text mt-2">Clients served across <br /> the world</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-3" data-aos="fade-up" data-aos-delay="600">
                  <div className="d-flex">
                    <div className="me-5">
                      <span className="number-text">3</span>
                      <div className="under-line"></div>
                    </div>
                    <div>
                      <p className="card-text mt-2">Start-ups fund raised with products we built for them</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Technology */}
      <section className="max-width-container">
        <div className="tech max-sec">
          <div className='row justify-content-center'>
            <div className='col-md-12'>
              <div className="row tech-p">
                <div className="col-lg-6 mb-2">
                  <img src={tech} className="img-fluid" alt='tech' />
                </div>
                <div className="col-lg-6 my-auto" data-aos="fade-up">
                  <h1 className="tech-head-text mb-3">Your trusted technology partner for success</h1>
                  <p className="tech-para-text">We are not in the business of just building apps. We are in the business of aiding your success. We have already helped three start-ups raise their first rounds of funding. We want you to be the next in line. We are nimble, agile and easy to work with.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 my-auto" data-aos="fade-up">
                  <h1 className="tech-head-text mb-3">Creating value and NOT just outputs</h1>
                  <p className="tech-para-text">We believe in Value first, Digital first, People First, Solutions First, and Commitments First. We will advise and consult with you and not just build things without solutioning first.</p>
                </div>
                <div className="col-lg-6 text-center">
                  <img src={tech2} className="img-fluid" alt='tech-2' />
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      {/* Gigavus */}
      <section className="gigavus">
        <div className='max-width-container'>
          <Slider {...products} ref={customeSlider}>
            {content.map((item, index) => (
              <div key={index}>
                <div className='row'>
                  <div className='col-sm-12 col-md-5 col-lg-5'>
                    <h1 className='mb-5'>Our Recent Work</h1>
                    <h2>{item.title}</h2>
                    {/* <span >{item.desc}</span> */}
                    <div className='gigavus-text'
                      dangerouslySetInnerHTML={{ __html: item.desc }}
                    />
                    <div className='prevnext d-flex'>
                      <img src={prev} alt='prev' onClick={previous} className='me-5'></img>
                      <img src={nex2} alt='next' onClick={next}></img>
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-7 col-lg-7 d-flex justify-content-center'>
                    <img src={item.image} className='img-fluid' alt='products' key={index} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section >
      {/* Services */}
      < section className="services">
        <div className="max-width-container">
          <div className="d-none d-sm-none d-md-block">
            <h1 className='mb-4'>Our Services</h1>
            <Link to="/services">
              <img src={service} className="img-fluid w-100" alt='services' />
            </Link>
          </div>
          <div className="my-3 d-sm-block d-md-none d-lg-none d-xl-none">
            <h1 className='mb-3'>Our Services</h1>
            <Slider {...services}>
              {servicesImgs.map((img, index) => (
                <div key={index}>
                  <img src={img} alt='logos' className='img-fluid' />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section >
      {/* Testimonials */}
      <section className="max-width-container">
        <div className="testimonials mb-4 max-sec">
          <section>
            <h1>Testimonials</h1>
          </section>
          <Slider {...testimonials}>
            {testi_imgs.map((item, index) => (
              <div key={index}>
                <div className="testimonials-card">
                  <div className="media-head">
                    <img src={quote} className="img-fluid mb-4" alt='quote' />
                    <div className='text-center d-block'>
                      <img src={item.image} className="img-fluid" alt='vp' />
                    </div>
                  </div>
                  <div className="media d-flex">
                    <div className="me-4">
                      <img src={item.pic} width='45px' height='45px' alt='profile' />
                    </div>
                    <div className="media-body">
                      <h2 className="mt-0">{item.name}</h2>
                      <p>{item.position}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section >
      {/* Letus */}
      <div className="letus">
        <div className="max-width-container max-sec">
          <div className="letus-sub">
            <p className="letus-content">Let us build your tomorrow, today</p>
            <div>
              <Link to="/proposal">
                <button className="border-0 letus-btn"><span>Get In Touch</span></button>
              </Link>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}
