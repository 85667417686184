import Navbar from "./includes/Navbar";
import service from "../assets/services/service.png";
import pipeline from "../assets/services/pipeline.png";
import agile from "../assets/services/agile.png";
import mPipeline from "../assets/services/m-pipeline.png";
import mAgile from "../assets/services/m-agile.png";
import cicd from "../assets/services/cicd.png";
import mCicd from "../assets/services/m-cicd.png";
import deliveryImg from "../assets/services/delivery.png";
import testingImg from "../assets/services/testing.png";
import trusted from "../assets/services/trusted.png";
import agileImg from "../assets/services/agile-d.png";
import integrationImg from "../assets/services/integration.png";
import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    borderRadius: 2,
    bgcolor: 'background.paper',
    boxShadow: 2,
    padding: 4
};

export default function Services() {

    const [openAgile, setOpenAgile] = useState(false);

    const handleOpenAgile = () => setOpenAgile(true);
    const handleCloseAgile = () => setOpenAgile(false);

    const [openDelivery, setOpenDelivery] = useState(false);

    const handleOpenDelivery = () => setOpenDelivery(true);
    const handleCloseDelivery = () => setOpenDelivery(false);

    const [openTesting, setOpenTesting] = useState(false);

    const handleOpenTesting = () => setOpenTesting(true);
    const handleCloseTesting = () => setOpenTesting(false);

    const [openIntegration, setOpenIntegration] = useState(false);

    const handleOpenIntegration = () => setOpenIntegration(true);
    const handleCloseIntegration = () => setOpenIntegration(false);

    return (
        <>
            <div className='services-main'>
                <div className='yellow-bg'>
                    <section className="pt-2 pb-1">
                        <Navbar />
                    </section>
                    <div className='max-width-container'>
                        <div className='deliver-bg row max-sec mt-3'>
                            <div className='col-md-6 col-sm-12 my-auto'>
                                <h1>Our Services</h1>
                                <p className='mt-3'>We provide world-class products and great user experiences. We always strive towards a digital future.
                                </p>
                            </div>
                            <div className='col-md-6 mt-4 text-center'>
                                <img src={service} alt='hero' className='img-fluid'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--Pipeline--> */}
                <div className='pipeline'>
                    <div className='text-center'>
                        <div className='d-none d-sm-none d-md-block'>
                            <img src={pipeline} alt='pipeline' className='img-fluid'></img>
                        </div>
                        <div className='d-sm-block d-md-none'>
                            <img src={mPipeline} alt='journey' className='img-fluid' />
                        </div>
                    </div>
                </div>
                {/* <!--Agile--> */}
                <div className='agile'>
                    <div className='text-center py-5'>
                        <div className='d-none d-sm-none d-md-block'>
                            <h1>We have robust processes in place</h1>
                            <img src={agile} alt='journey' className='img-fluid' />
                        </div>
                        <div className='d-sm-block d-md-none'>
                            <img src={mAgile} alt="Workplace" useMap="#workmap" className='img-fluid' />
                            <map name="workmap">
                                <area shape="rect" coords="130,240,230,330" alt="Delivery" onClick={handleOpenAgile} />
                                <area shape="rect" coords="10,290,100,390" alt="Delivery" onClick={handleOpenDelivery} />
                                <area shape="rect" coords="130,355,220,450" alt="Testing" onClick={handleOpenTesting} />
                                <area shape="rect" coords="250,315,335,395" alt="Computer" onClick={handleOpenIntegration} />
                            </map>
                            <Modal
                                open={openAgile}
                                onClose={handleCloseAgile}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <img src={agileImg} alt='agile' className='img-fluid' />
                                </Box>
                            </Modal>
                            <Modal
                                open={openDelivery}
                                onClose={handleCloseDelivery}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <img src={deliveryImg} alt='journey' className='img-fluid' />
                                </Box>
                            </Modal>
                            <Modal
                                open={openTesting}
                                onClose={handleCloseTesting}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <img src={testingImg} alt='journey' className='img-fluid' />
                                </Box>
                            </Modal>
                            <Modal
                                open={openIntegration}
                                onClose={handleCloseIntegration}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <img src={integrationImg} alt='journey' className='img-fluid' />
                                </Box>
                            </Modal>
                        </div>
                    </div>
                </div>
                {/* <!--CICD--> */}
                <div className='pipeline'>
                    <div className='text-center py-5'>
                        <div className='d-none d-sm-none d-md-block'>
                            <img src={cicd} alt='journey' className='img-fluid' />
                        </div>
                        <div className='d-sm-block d-md-none'>
                            <img src={mCicd} alt='journey' className='img-fluid' />
                        </div>
                    </div>
                </div>
                {/* <!--trusted--> */}
                <div className='partners'>
                    <div className='text-center'>
                        <img src={trusted} alt='journey' className='img-fluid' />
                    </div>
                </div>
            </div>
        </>

    )
}
