import ReactDOM from "react-dom/client";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../src/index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import 'aos/dist/aos.css';
import 'react-vertical-timeline-component/style.min.css';

// Pages
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Home from './components/Home';
import Proposal from './components/Proposal';
import Services from "./components/Services";
import Products from "./components/Products";
import Careers from "./components/Careers";
import JobDetails from "./components/JobDetails";
import Whoweare from "./components/Whoweare";
import Howwedeliver from "./components/Howwedeliver";
import ClientSuccess from "./components/ClientSuccess";
import Index from "./components";

// Datas
import { JobListData } from "./components/helpers/Data";

// Includes
import PageNotFound from "./components/404";

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route element={<Index />}>
          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="proposal" element={<Proposal />} />
          <Route path="services" element={<Services />} />
          <Route path="products" element={<Products />} />
          <Route path="careers" element={<Careers JobListData={JobListData} />} />
          <Route path="jobs/:slug" element={<JobDetails JobListData={JobListData} />} />
          <Route path="who-we-are" element={<Whoweare />} />
          <Route path="how-we-deliver" element={<Howwedeliver />} />
          <Route path="clientsuccess" element={<ClientSuccess />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </>
);