import React, { useState, useEffect } from 'react'
import Navbar from "./includes/Navbar";
import hero from "../assets/whoweare/hero.webp";
import journey from "../assets/whoweare/journey.png";
import frame from "../assets/whoweare/frame.png";
import { partnerLogos } from "./helpers/Data";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import Particles from 'react-tsparticles';
import { loadFull } from "tsparticles";
import { useLocation } from 'react-router-dom';
import { Box, Tab, Tabs, Typography } from '@mui/material';

const particlesInit = async (main) => {
  await loadFull(main);
};

const particlesLoaded = (container) => {
  // console.log(container);
};

export default function Whoweare() {

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      {/* Particles Section */}
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: "#F2F2F2",
            },
          },
          fullScreen: {
            enable: false,
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              resize: true,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.5,
              },
            },
          },
          particles: {
            color: {
              value: ["#939393", "#FFCB20"]
            },
            links: {
              color: "#000000",
              distance: 100,
              enable: true,
              opacity: 0.4,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outModes: {
                default: "bounce",
              },
              random: false,
              speed: 1.2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 900,
              },
              value: 25,
            },
            opacity: {
              value: 0.6,
            },
            shape: {
              type: "polygon",
              polygon: {
                "nb_sides": 6
              },
            },
            size: {
              value: { min: 4, max: 7 },
            },
          },
          detectRetina: true,
        }} />
      <div className='yellow-bg whoweare'>
        <section className="pt-2 pb-1">
          <Navbar />
        </section>
        <div className='max-width-container'>
          <div className='row max-sec'>
            <div className='col-md-6 my-auto'>
              <h1>Who We Are</h1>
              <p className='mt-3'>We provide world-class products and great user experiences. We always strive towards a digital future.</p>
            </div>
            <div className='col-md-6 mt-4 text-center'>
              <img src={hero} alt='hero' className='img-fluid'></img>
            </div>
          </div>
        </div>
      </div>
      {/* <!--Vision and Mission--> */}
      <div className='vision'>
        <div className='row max-width-container'>
          <div className='col-md-6 col-lg-6 my-auto'>
            <Box>
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange} TabIndicatorProps={{ style: { background: "#FFCB20" } }}>
                  <Tab
                    label={
                      <div className={tabIndex === 0 ? "active-tab-text " : "unactive-tab-text"}>
                        Vision
                      </div>
                    }
                  />
                  <Tab label={
                    <div className={tabIndex === 1 ? "active-tab-text " : "unactive-tab-text"}>
                      Mission
                    </div>
                  } />
                  <Tab label={
                    <div className={tabIndex === 2 ? "active-tab-text " : "unactive-tab-text"}>
                      philosophy
                    </div>
                  } />
                </Tabs>
              </Box>
              <Box sx={{ padding: 2 }}>
                {tabIndex === 0 && (
                  <Box>
                    <Typography>Strive towards realising the full potential of ‘Digital’ in every home, every office and every pocket.</Typography>
                  </Box>
                )}
                {tabIndex === 1 && (
                  <Box>
                    <Typography>Build high quality, well-engineered products.</Typography>
                  </Box>
                )}
                {tabIndex === 2 && (
                  <Box>
                    <Typography>Digital First, People First, Solutions First, and Commitments First.</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </div>
        </div>
      </div>
      {/* <!--journey--> */}
      <div className='max-width-container text-center'>
        <div className='d-none d-sm-none d-md-block'>
          <img src={journey} alt='journey' className='img-fluid' />
        </div>
      </div>
      <div className='journey d-sm-block d-md-none'>
        <h1>Our Journey</h1>
        <div className='px-2'>
          <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: 'rgba(86, 82, 84, 1)', color: '#fff', border: '1px solid rgba(253, 202, 32, 1)' }}
              contentStyle={{ borderTop: '3px solid rgba(86, 82, 84, 1)' }}
              icon={'Apr 2019'}
            >
              <p className='mt-2'>
                Instrive founded on
                April 1, 2019 with only
                one member and a
                dream.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: 'rgba(253, 202, 32, 1)', color: 'rgba(86, 82, 84, 1)', border: '1px solid rgba(86, 82, 84, 1)' }}
              contentStyle={{ borderTop: '3px solid rgba(253, 202, 32, 1)' }}
              icon={'Apr 2020'}
            >
              <p className='mt-2'>
                Our DevOps and end-to-
                end CICD capabilities
                standarised. Two
                products completed
                from scratch.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: 'rgba(238, 238, 238, 1)', color: 'rgba(86, 82, 84, 1)', border: '1px solid rgba(86, 82, 84, 1)' }}
              contentStyle={{ borderTop: '3px solid rgba(86, 82, 84, 1)' }}
              icon={'Feb 2021'}
            >
              <p className='mt-2'>
                Five products completed
                including our own inhouse
                product. We start our
                expansion in ernest.
              </p>
            </VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: 'rgba(86, 82, 84, 1)', color: '#fff', border: '1px solid rgba(253, 202, 32, 1)' }}
              contentStyle={{ borderTop: '3px solid rgba(253, 202, 32, 1)' }}
              icon={'Apr 2021'}
            >
              <p className='mt-2'>
                First in-house product
                Hydrameet is
                recognised as one of
                India's top VC Solutions
                by the Government of
                India
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: 'rgba(253, 202, 32, 1)', color: 'rgba(86, 82, 84, 1)', border: '1px solid rgba(86, 82, 84, 1)' }}
              contentStyle={{ borderTop: '3px solid rgba(86, 82, 84, 1)' }}
              icon={'July 2022'}
            >
              <p className='mt-2'>
                8 Enterprise grade
                products.
                20 member team.
                40 on-demand
                specialists.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              iconStyle={{ background: 'rgba(238, 238, 238, 1)', color: 'rgba(86, 82, 84, 1)', border: '1px solid rgba(86, 82, 84, 1)' }}
              contentStyle={{ borderTop: '3px solid rgba(253, 202, 32, 1)' }}
              icon={'Sep 2022'}
            >
              <p className='mt-2'>
                Multiple new
                partnerships and on
                track to delivering three
                more products by end of
                2022
              </p>
            </VerticalTimelineElement>
          </VerticalTimeline>
        </div>
      </div>
      {/* <!--frame--> */}
      <div className='leadership'>
        <div className='row max-width-container'>
          <div className='col-md-6 my-auto'>
            <h4 className='mt-5 mb-4'>Our Leadership</h4>
            <p>Founder of Instrive Softlabs. He has an engineering degree in Electronics & Communication Engineering and hails from the district of Tiruchirappalli, Tamil Nadu.</p>

            <p>Ashok started his career in the year 2010 as a web developer in a startup and grew to become a Product Architect. Over these years, he had a vision of becoming an entrepreneur and started working on several of his own products which include a wide range of real-time communication features.</p>

            <p>In July 2019, he started “Instrive Softlabs Pvt Limited” which provides development services for overseas clients. Since then, Ashok and his team have developed several international, enterprise-grade products for its clients.</p>

            <p>He always had the vision of developing In-house products via Instrive and the Innovation challenge from MeiTy only accelerated this goal. While Ashok’s strength lies in technology, he prides his ability to put himself both in the shoes of developers as well as customers.</p>

            <p>Ashok believes in the core philosophy of ‘Innovation demands freedom’ and ensures that all his team members enjoy a great deal of autonomy.</p>
          </div>
          <div className='col-md-6 text-center my-auto'>
            <img src={frame} alt='frame' className='img-fluid'></img>
          </div>
        </div>
      </div>
      {/* <!--Trusted By--> */}
      <div className='trusted py-5'>
        <div className='max-width-container'>
          <h1>Trusted By:</h1>
          <div className='row'>
            {partnerLogos.map((logo, index) => (
              <div className='col-sm-12 col-md-4 col-lg-3 mb-3 text-center' key={index}>
                <img src={logo} alt='logos' />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div >
  )
}
